// design
import { iconClose } from "@/design/icon/iconConst";

// model
import { commandNames } from "@/model/common/commands/commandModel";
import { eventNames } from "@/model/common/events/eventConst";

/**
 * modal Dialog Mixin encapsulate basic modal Dialog functionality
 */
export const modalDialogMixin = {
  data() {
    return {
      iconCloseDialog: iconClose
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * get/set showDialog value which indicates whether the dialog is visible or hidden.
     */
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (value) {
          this.$emit(eventNames.opened);
        } else {
          this.$emit(eventNames.close);
        }
      }
    },

    /**
     * dialog Icon (provides default dialog Icon)
     * @return {undefined} default dialog Icon name
     */
    dialogIcon() {
      return undefined;
    },

    /**
     * compute Dialog Icon visibility
     * @return {boolean} true when Dialog Icon is visible
     */
    visibleDialogIcon() {
      return !this.dialogIcon;
    },

    /**
     * dialog Title (provides default dialog Title)
     * @return {undefined} default dialog Title
     */
    dialogTitle() {
      return undefined;
    },

    /**
     * Close Dialog Tooltip
     * @return {string} close Dialog Tooltip
     */
    closeDialogTooltip() {
      return this.dialogTitle
        ? `${this.dialogCloseCommandLabel} ${this.dialogTitle} Dialog`
        : `${this.dialogCloseCommandLabel} Dialog}`;
    },

    /**
     * OK command Label
     * @return {string} ok Dialog Label
     */
    dialogOkCommandLabel() {
      return commandNames.ok;
    },

    /**
     * dialog Cancel command Label
     * @return {string} dialog Cancel Command Label
     */
    dialogCancelCommandLabel() {
      return commandNames.cancel;
    },

    /**
     * dialog Close Command Label
     * @return {string} dialog Close Command Label
     */
    dialogCloseCommandLabel() {
      return commandNames.close;
    }
  },
  methods: {
    /**
     * open Dialog
     */
    openDialog() {
      this.showDialog = true;
    },
    /**
     *  close Dialog
     */
    closeDialog() {
      this.showDialog = false;
    }
  }
};
