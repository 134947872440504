/**
 * prompt Type
 * @type {Readonly<{normal: string, critical: string, success: string, warning: string, info: string}>}
 */
const promptType = Object.freeze({
  normal: "normal",
  success: "success",
  info: "info",
  warning: "warning",
  critical: "error"
});

/**
 * create Prompt
 * @param {string} text required text of the prompt
 * @param {string} type optional promptType
 * @param {string} subtitle optional subtitle
 * @param {string} hint optional hint
 * @return {{text: string, subtitle: string|undefined|null, hint: string|undefined|null, type: string|undefined|null}}
 */
const createPrompt = (
  text,
  type = undefined,
  subtitle = undefined,
  hint = undefined
) => {
  return {
    text: text,
    type: type,
    subtitle: subtitle,
    hint: hint
  };
};

export { promptType, createPrompt };
