// mixins
import { modalDialogMixin } from "@/mixins/shared/base/dialog/modalDialogMixin";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";

/**
 * dialogOutlineMixin encapsulate dialog Outline functionality
 */
export const dialogOutlineMixin = {
  mixins: [modalDialogMixin, alertableMixin, reactiveMixin],
  components: {
    DialogOutline: () =>
      import("@/components/shared/core/dialogs/DialogOutline"),
    BaseButton: () => import("@/components/shared/base/BaseButton"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  data() {
    return {
      displayProgress: false
    };
  },
  methods: {
    /**
     * Handle on Dialog Opened event
     */
    onDialogOpened() {
      this.internalAlert = undefined;
    },

    /**
     * Handle on Dialog Closed event
     */
    onDialogClosed() {
      this.internalAlert = undefined;
    },

    /**
     * wait before the specified function or code is executed.
     * @param {number} timeOut The time, in milliseconds that the timer should wait before the specified function or code is executed
     * @return {Promise<unknown>}
     */
    async delay(timeOut = 1500) {
      return new Promise(resolve => setTimeout(resolve, timeOut));
    },

    /**
     * Auto Close Dialog
     * @return {Promise<void>}
     */
    async autoCloseDialog(message) {
      try {
        this.internalAlert = this.createAlert(
          this.alertTypeName.success,
          message
        );
        await this.delay();
        this.closeDialog();
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.onDialogOpened();
      } else {
        this.onDialogClosed();
      }
    }
  }
};
