/**
 * Alert Type (map to vuetify Alert types)
 * @type {{success: string, warning: string, error: string, info: string}}
 */
const alertType = Object.freeze({
  success: "success",
  info: "info",
  warning: "warning",
  error: "error"
});

/**
 * Get the list of available Alert Types
 * @type {(string)[]}
 */
const alertTypes = Object.freeze([
  alertType.success,
  alertType.info,
  alertType.warning,
  alertType.error
]);

/**
 * Alert border
 * @type {Readonly<{top: string, left: string, bottom: string, right: string}>}
 */
const border = Object.freeze({
  top: "top ",
  right: "right ",
  bottom: "bottom ",
  left: "left"
});

/**
 * Alert borders
 * @type {(string)[]}
 */
const borders = Object.freeze([
  border.top,
  border.right,
  border.bottom,
  border.left
]);

/**
 * create Alert
 * @param {string} type Alert type
 * @param {string} message Alert message
 * @param {boolean} dismissible dismissible Alert
 * @return {{type : string, message : string, dismissible : boolean}}
 */
const createAlert = (type, message, dismissible = false) => {
  return {
    type: type,
    message: message,
    dismissible: dismissible
  };
};

/**
 * format Alert Error
 * @param action full operation action
 * @param error error message
 * @return {`Couldn't ${string}. ${string}`}
 */
const formatAlertError = (action, error) => {
  return `Couldn't ${action}. ${error}`;
};

export {
  alertType,
  alertTypes,
  border,
  borders,
  createAlert,
  formatAlertError
};
