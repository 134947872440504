// design
import {
  createPrompt,
  promptType
} from "@/design/components/prompt/promptModel";
import { colorTheme } from "@/design/colors/mdColors";

export const promptAbleMixin = {
  components: {
    BasePrompt: () => import("@/components/shared/base/BasePrompt")
  },
  data() {
    return {
      visiblePrompt: true,
      promptTypeName: promptType
    };
  },
  computed: {
    /**
     * Prompt text
     * remarks: designed to be overwritten
     * @return {string} Prompt text
     */
    promptText() {
      return "Do you want to proceed?";
    },

    /**
     * compute prompt Subtitle
     * remarks: designed to be overwritten
     * @return {string} prompt Subtitle
     */
    promptSubtitle() {
      return undefined;
    },

    /**
     * compute prompt Hint (toolTip)
     * remarks: designed to be overwritten
     * @return {string}  prompt Hint (toolTip)
     */
    promptHint() {
      return undefined;
    },

    /**
     * current Prompt Type by default
     * remarks: designed to be overwritten
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.normal;
    },

    /**
     * prompt Color Class
     * @return {string|undefined} suggested prompt Color Class
     */
    promptColorClass() {
      switch (this.currentPromptType) {
        case promptType.normal: {
          return undefined;
        }
        case promptType.warning: {
          return colorTheme.warning;
        }
        case promptType.info: {
          return colorTheme.info;
        }
        case promptType.success: {
          return colorTheme.success;
        }
        case promptType.critical: {
          return colorTheme.error;
        }
        default:
          return undefined;
      }
    }
  },
  methods: {
    /**
     * create Prompt
     * @return {{text: string, subtitle: (string|undefined|null), hint: (string|undefined|null), type: (string|undefined|null)}}
     */
    createPrompt() {
      return createPrompt(
        this.promptText,
        this.currentPromptType,
        this.promptSubtitle,
        this.promptHint
      );
    }
  }
};
