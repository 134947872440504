// design
import {
  alertType,
  createAlert,
  formatAlertError
} from "@/design/components/alert/alertModel";

// utils
import { isEmpty } from "@/utils";

// model
import { eventNames } from "@/model/common/events/eventConst";

export const alertableMixin = {
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert")
  },
  data() {
    return {
      /**
       * internal alert to be displayed
       * @type {{type: string, message: string, dismissible : boolean}}
       */
      internalAlert: undefined,

      /**
       * alert Type name enum
       * @type {{success: string, warning: string, error: string, info: string}}
       */
      alertTypeName: alertType
    };
  },
  computed: {
    showAlert: {
      get() {
        return this.visibleAlert;
      },
      set(value) {
        if (!value) {
          if (this.internalAlert) {
            this.internalAlert.message = undefined;
          }
        }
        this.$emit(eventNames.alertVisibilityChanged, value);
      }
    },

    /**
     * determines whether alert should be visible
     * @return {boolean} true if alert should be visible
     */
    visibleAlert() {
      return !isEmpty(this.internalAlert?.message);
    },

    /**
     * current Alert Type
     * @return {string|undefined} current Alert Type
     */
    currentAlertType() {
      return isEmpty(this.internalAlert?.message)
        ? alertType.info
        : this.internalAlert.type;
    },

    /**
     * alert Message
     * @return {string|undefined}
     */
    alertMessage() {
      return this.internalAlert?.message;
    },

    /**
     * compute whether Alert should be Dismissible
     * @return {boolean} if true Alert should be Dismissible
     */
    isAlertDismissible() {
      return this.internalAlert?.dismissible ?? false;
    }
  },
  methods: {
    /**
     * create Alert
     * @param {string} type Alert type
     * @param {string} message Alert message
     * @param {boolean} dismissible Alert dismissible
     * @return {{type: string, message: string, dismissible: boolean}}
     */
    createAlert(type, message, dismissible = false) {
      return createAlert(type, message, dismissible);
    },

    /**
     * create Error type Alert
     * @param {string} message Error Alert message
     * @param {boolean} dismissible Error Alert dismissible
     * @return {{type: string, message: string, dismissible: boolean}}
     */
    createAlertError(message, dismissible = false) {
      return createAlert(alertType.error, message, dismissible);
    },

    /**
     * create Warning type Alert
     * @param {string} message Warning Alert message
     * @param {boolean} dismissible Warning Alert dismissible
     * @return {{type: string, message: string, dismissible: boolean}}
     */
    createAlertWarning(message, dismissible = false) {
      return createAlert(alertType.warning, message, dismissible);
    },

    /**
     * create Info type Alert
     * @param {string} message Info Alert message
     * @param {boolean} dismissible Info Alert dismissible
     * @return {{type: string, message: string, dismissible: boolean}}
     */
    createAlertInfo(message, dismissible = false) {
      return createAlert(alertType.info, message, dismissible);
    },

    /**
     * create Success type Alert
     * @param {string} message Success Alert message
     * @param {boolean} dismissible Success Alert dismissible
     * @return {{type: string, message: string, dismissible: boolean}}
     */
    createAlertSuccess(message, dismissible = false) {
      return createAlert(alertType.success, message, dismissible);
    },

    /**
     * format Alert Error
     * @param {String} action
     * @param {String} error
     * @return {string} formatted Alert Error
     */
    formatAlertError(action, error) {
      return formatAlertError(action, error);
    },

    /**
     * clear current Alert
     */
    clearAlert() {
      this.internalAlert = undefined;
    }
  }
};
